import * as React from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout page="internal">
    <Seo title="404: Not found" />
    <Container className="container">
      <div className="customWrapper">
        <div className="defaultWrapper">
          <h1>404: Not Found</h1>
          <p>Unfortunetly the page you are trying to access doesn't exist.</p>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
